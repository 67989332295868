.features {
  background: #f9f9f9;
  padding: 2rem 1rem;
  text-align: center;
  font-family: 'Baloo 2', sans-serif;
}

.feature-cards {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 1.5rem;
  width: 100%;
  max-width: 100%; /* Allow the cards to stretch across the full width */
}

.feature-card {
  background: white;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 1.5rem;
  width: calc(20% - 1.5rem); /* Adjust width to fit 5 cards across screen */
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  margin-bottom: 2rem; /* Space for wrapping cards */
}

.feature-card:hover {
  transform: scale(1.05);
}

.feature-icon {
  font-size: 2.5rem;
  margin-bottom: 1rem;
  color: green;
}

.feature-card h3 {
  font-size: 1.5rem;
  color: green;
  margin-bottom: 0.5rem;
}

.feature-card .custom-icon {
  width: 50;
  height: 50;
}

.feature-card p {
  font-size: 1rem;
  color: #555;
}

/* Responsive Styles */
@media (max-width: 1200px) {
  .feature-card {
    width: calc(25% - 1.5rem); /* 4 cards per row */
  }
}

@media (max-width: 768px) {
  .feature-card {
    width: calc(50% - 1.5rem); /* 2 cards per row */
  }
}

@media (max-width: 480px) {
  .feature-card {
    width: 100%; /* 1 card per row on small screens */
  }
}
