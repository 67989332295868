.footer {
    background: linear-gradient(135deg, green, #01851deb);
    color: white;
    text-align: center;
    padding: 1rem 0;
  }
  
  .footer nav a {
    color: white;
    margin: 0 0.5rem;
    text-decoration: none;
  }

  .footer p {
    color: white;
  }
  
  .footer nav a:hover {
    text-decoration: underline;
  }
  