  .hero {
    background: linear-gradient(135deg, green, green);
    color: white;
    text-align: center;
    padding: 2rem 1rem;
  }

  .hero h1 {
    font-family: 'Baloo 2', sans-serif;
    font-size: 2.5rem;
    color: white;
  }
  
  .hero p {
    font-family: 'Baloo 2', sans-serif;
    font-size: 1.2rem;
    color: white;
  }
  
  .hero-buttons button {
    background: white;
    color: #9e2af7;
    border: none;
    padding: 1rem 2rem;
    margin: 1rem;
    font-size: 1rem;
    cursor: pointer;
    border-radius: 10px;
  }
  
  .hero-buttons button:hover {
    background: #9e1fc5;
    color: white;
  }
  
  .hero-buttons a {
    margin-right: 20px; /* Adds space to the right of each button */
  }
  

  .hero-image {
    width: 200;
    height: 200;
  }